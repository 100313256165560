//@flow
import * as React from 'react'

import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero, {
  modelLegalPageHero,
  type LegalPageHeroData,
} from '../components/organisms/hero'
import {
  FlexColumnGrid,
  InnerPageContainer,
  FullWidthBox,
  type PageMeta,
} from '../components/containers'
import Article from '../components/molecules/richTextArticle'

import SliceZone, {
  type SliceZoneData,
} from '../components/containers/sliceZone'

//import { SidebarMenu } from '../components/molecules/sidebarMenu'

import { type RichTextAry } from '../types/flow/gatsby-source-prismic-graphql'

export type LegalPageData = {|
  prismic: {|
    allLegals: {|
      edges: {|
        node: {|
          _meta: {| uid: string |},
          ...LegalPageHeroData,
          article: RichTextAry,
          body: SliceZoneData,
          seo_data: PageMeta[],
          theme: {|
            _meta: {|
              id: string,
              uid: string,
              type: string,
            |},
          |},
        |},
      |}[],
    |},
  |},
|}

function LegalPage({ data }: { data: LegalPageData }): React.Node {
  const doc = data.prismic.allLegals.edges.slice(0, 1).pop()
  if (!doc) return null
  //$FlowFixMe
  const heroData = modelLegalPageHero(doc.node)
  let slices = Array.isArray(doc.node.body) && [...doc.node.body]
  return (
    <Layout
      themeName={doc.node.theme._meta.uid}
      path={doc.node._meta.uid}
      seoData={doc.node.seo_data.pop()}
    >
      <Hero pt={9} pb={9} data={heroData} hideBadge></Hero>
      <InnerPageContainer pt={0} pb={4} fullWidth>
        <FlexColumnGrid
          columns={[12]}
          spaceBetween={[3]}
          pt={[4, 4, 6]}
          pb={[4, 4, 6]}
        >
          <Article colSpan={[12, 12, 10, 7]} nudge={[0, 0, 1, 2]}>
            {doc.node.article}
          </Article>
          <FullWidthBox order={2} colSpan={12}>
            {slices && <SliceZone>{slices}</SliceZone>}
          </FullWidthBox>
        </FlexColumnGrid>
      </InnerPageContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LegalsQuery($uid: String! = "cookie-policy") {
    prismic {
      allLegals(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            article
            body {
              ... on PRISMIC_LegalBodyCta {
                type
                label
                primary {
                  button_link {
                    _linkType
                    ... on PRISMIC_Services {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Contact {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Legal {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                      size
                      name
                    }
                    ... on PRISMIC__ImageLink {
                      _linkType
                      width
                      url
                      size
                      name
                      height
                    }
                  }
                  button_text
                  description
                  section_title
                  testimonial {
                    ... on PRISMIC_Testimonials {
                      name
                      location
                      testimonial
                    }
                  }
                }
              }
              ... on PRISMIC_LegalBodyHighlights_row {
                type
                label
                fields {
                  description
                  heading
                }
              }
              ... on PRISMIC_LegalBodyLocations {
                type
                label
                fields {
                  locations {
                    ... on PRISMIC_Locations {
                      location
                      coordinates
                    }
                  }
                }
                primary {
                  leading_sentence
                  call_to_action
                  button_link {
                    _linkType
                    ... on PRISMIC_Locations {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC_Services {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC_Contact {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC_Legal {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC__ImageLink {
                      _linkType
                      width
                      url
                      size
                      name
                      height
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      name
                      size
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_LegalBodyPromotion {
                type
                label
                primary {
                  background_image
                  background_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  button_link {
                    _linkType
                    ... on PRISMIC_Services {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                    ... on PRISMIC_Contact {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC_Legal {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      name
                      size
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      _linkType
                      width
                      url
                      size
                      name
                      height
                    }
                  }
                  button_text
                  description
                  heading
                }
              }
              ... on PRISMIC_LegalBodyService_grid {
                type
                label
                fields {
                  description
                  link {
                    ... on PRISMIC_Services {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                  service_image
                  service_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            main_heading
            seo_data {
              meta_description
              meta_title
              opengraph_description
              opengraph_image
              opengraph_locale
              opengraph_site_name
              opengraph_title
              schema
              twitter_card_type
              twitter_site
            }
            summary
            theme {
              ... on PRISMIC_Theme {
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`
export default LegalPage
